import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CommonService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/common', httpClient, toastrService);
  }

  getFinancialYears() {
    return this.httpClient.get(this.baseUrl + 'api/common/financial-years')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getPhases() {
    return this.httpClient.get(this.baseUrl + 'api/common/phases')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getCountries() {
    return this.httpClient.get(this.baseUrl + 'api/common/countries')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getRegions() {
    return this.httpClient.get(this.baseUrl + 'api/common/regions')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getStates(regionId?: string) {
    let query = {
      regionId: regionId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/states' + '?' + this.toQueryString(query))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getDistricts(stateId) {
    let query = {
      stateId: stateId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/districts' + '?' + this.toQueryString(query))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getSchools(districtId, schoolLevelId) {
    let query = {
      districtId: districtId,
      schoolLevelId: schoolLevelId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/schools' + '?' + this.toQueryString(query))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getDesignationTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/designation-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getDesignations(designationTypeId?: number) {
    let query = {
      designationTypeId: designationTypeId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/designations' + '?' + this.toQueryString(query))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getSchoolLevels() {
    return this.httpClient.get(this.baseUrl + 'api/common/school-levels')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getSchoolTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/school-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getVacancyTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/vacancy-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getCategories() {
    return this.httpClient.get(this.baseUrl + 'api/common/categories')
      .pipe(catchError(this.handleError.bind(this)))
  }
  
  getRecruitmentCategories() {
    return this.httpClient.get(this.baseUrl + 'api/common/recruitment-categories')
      .pipe(catchError(this.handleError.bind(this)))
  } 
 
  getDiseases() {
    return this.httpClient.get(this.baseUrl + 'api/common/diseases')
      .pipe(catchError(this.handleError.bind(this)))
  }

  GetSingleLadyTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/single-lady-type')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getJobPlaceTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/job-place-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getJobTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/job-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getRelations() {
    return this.httpClient.get(this.baseUrl + 'api/common/relations')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getDisabilities() {
    return this.httpClient.get(this.baseUrl + 'api/common/disabilities')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getCertTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/cert-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getReligions() {
    return this.httpClient.get(this.baseUrl + 'api/common/religions')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getGenders() {
    return this.httpClient.get(this.baseUrl + 'api/common/genders')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getTransferReasons() {
    return this.httpClient.get(this.baseUrl + 'api/common/transfer-reasons')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getTransferCategories() {
    return this.httpClient.get(this.baseUrl + 'api/common/transfer-categories')
      .pipe(catchError(this.handleError.bind(this)))
  }
  
  getTransferCategoriesVacantPost() {
    return this.httpClient.get(this.baseUrl + 'api/common/transfer-categories-vacant-post')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAppointmentModes() {
    return this.httpClient.get(this.baseUrl + 'api/common/appointment-modes')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getTotalDisplaced() {
    return this.httpClient.get(this.baseUrl + 'api/reports/dashboard-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getGrievanceTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/grievance-types')
      .pipe(catchError(this.handleError.bind(this)))
  } 
}